<!-- 特种水泥 -->
<template>
  <div class="cement_main">
    <!-- 顶部 -->
    <div class="background_img">
      <img style="width:100%;height:100%" src="../../assets/banner.png" alt="">
      <!-- <div class="background_img_title">
        <span>拼搏 创新 和谐 高效</span>
      </div>
      <div class="background_img_title_two">
        <span>携手客户 共创双赢</span>
      </div>
      <div class="background_img_title_line"></div>
      <div class="background_img_title_three flex-row">
        <img class="margin-r-10" style="width: 29px;height: 27px;" src="../../assets/u294.png" alt="">
        <span>嘉华特种水泥股份有限公司</span>
      </div> -->
    </div>
    <!-- 水泥介绍 -->
    <div class="introduce flex-row">
      <div class="introduce_background">
        <div class="introduce_background_top flex-row">
          <div class="introduce_background_top_left">
            <div class="introduce_background_top_left_line"></div>
            <div
              style="font-family: 'Arial Normal', 'Arial';font-weight: 400;color: #003399;font-size:22px;margin-bottom:10px">
              About us</div>
            <div style="font-size:22px;font-family: 'Arial Normal', 'Arial';font-weight: 400;color: #000000;">关于</div>
            <div style="font-size:32px;font-family:'Arial Negreta', 'Arial';font-weight:700;color:#000000;">嘉华特种水泥</div>
          </div>
          <div class="introduce_background_top_right">
            公司位于四川省乐山大佛景区，其前身“嘉华水泥公司乐山制造厂”始建于1939年，是四川省最早的水泥厂。公司成立于1994年，2012年3月加盟中国建材，2021年11月加入新天山水泥，是目前国内最大的特种水泥生产研发企业。<br />
            公司主导产品固井材料及特种工程材料系列产品，在国内居于领先地位。固井材料系列产品除满足国内各大油田需求外，还远销东南亚、中亚、非洲等海外市场；特种工程材料系列产品产品覆盖金沙江、大渡河、岷江、嘉陵江、澜沧江等七大流域，广泛应用于川藏铁路、白鹤滩、乌东德、溪洛渡等国家重点工程。
          </div>
        </div>
        <div class="introduce_background_bottom flex-row">
          <div class="introduce_background_bottom_left">
            <p style="margin-top:70px">公司</p>
            <p>资质</p>
          </div>
          <div class="introduce_background_bottom_right">
            <div class="introduce_animation">
              <div class="carousel-item" v-for="(item,index) in backgroundimgList" :key="index">
                <img style="width: 217px;height: 217px;" :src="item.src" :title="item.title"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="serve_say">
      <p
        style="font-family: 'Arial Normal', 'Arial';font-weight: 400;color: #003399;font-size:22px;white-space: nowrap;">
        Product Introduction</p>
      <p
        style="font-family: 'Arial Normal', 'Arial';font-weight: 400;color: #000000;font-size:30px;white-space: nowrap;">
        产品介绍</p>
    </div>
    <!-- 产品介绍 底部线-->
    <div class="serve_say_line"></div>
    <!-- 产品介绍内容 -->
    <div class="Product_Introduction_content">
      <div class="flex-space-center">
        <div class="Product_Introduction_content_top_div flex-row margin-b-20">
          <img style="width:528px;height:100% "
            src="https://www.xjjcjc.com:7443/api/henghe/blob/84868f27ddad1396294e3a0e9c61487b.jpg" alt="">
          <div class="Product_Introduction_content_top_div_title">超细硅酸盐水泥</div>
          <div class="dialog_Product_Introduction_content_top_div flex-row-start-between">
            <div class="dialog_Product_Introduction_content_top_div_left">
              <div class='dialog_title'>简介</div>
              <span class="dialog_title_span">超细硅酸盐水泥是以黄通硅酸盐熟料为基材，加入一定量的辅材经特殊粉磨工艺制成。</span>
              <div class='dialog_title'>优点</div>
              <span class="dialog_title_span" style="line-height: 24px">无毒无味，对地下水皮环境无污染，属环保型灌浆材料。<br />
                具有一定的颗粒级配，早期强度高、安全环保、耐久性好等特点。</span>
            </div>
            <div class="dialog_Product_Introduction_content_top_div_right flex-column-align-center">
              <img style="width: 128px;height: 161px;"
                src="https://www.xjjcjc.com:7443/api/henghe/blob/0842c735fa86603bd2713a0e9c60b8d5.jpg" alt="">
              <el-button @click="gobuy('1000414600')"
                style="background-color: rgba(4, 52, 140, 1); color:#fff;width: 118px;margin-top:15px"
                round>立刻购买</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-space-center">
        <div class="Product_Introduction_content_top_div flex-row" style="margin-bottom: 150px">
          <div class="Product_Introduction_content_top_div_title-other">快硬硫铝酸盐水泥</div>
          <img style="width:528px;height:100% "
            src="https://www.xjjcjc.com:7443/api/henghe/blob/df9ab815c82bdc8271c73a0e9c6273b0.jpg" alt="">
          <div class="dialog_Product_Introduction_content_top_div-other flex-row-start-between" style="height: 357px;">
            <div class="dialog_Product_Introduction_content_top_div_left">
              <div class='dialog_title'>简介</div>
              <span
                class="dialog_title_span">快硬硫铝酸盐水泥:是以适当成分的生料，经煅烧所得以无水硫铝酸钙和硅酸二钙为主要矿物成分的水泥熟料掺和石灰石、适量石有共同磨细制成的，具有早期强度高的水硬性胶凝材料，代号R.SAC.强度等级以3d抗压强度分为:42.5、52.
                5、62.5、 72. 5四个等级。</span>
              <div class='dialog_title'>优点</div>
              <span class="dialog_title_span"
                style="line-height: 24px">(1)早强、高强:一天抗压强度达到30.0~55.0Mpa,三天达到42.5-72.5Mpa.<br />
                (2)高抗冻性:在0-10°C低温使用，早期强度是波特兰水泥的5-6倍;0~20°C加少量外加剂，3~7天强度可达到设计标号的70~80%。冻融循环300次强度损失不明显。<br />
                (3)高抗渗性:水泥石结构致密，混凝土抗渗性能是同标号硅酸盐水泥的2~3倍。<br />
                (4)抗腐蚀好:尤其是抗海水腐蚀和盐碱地施工抗腐蚀性能优于高抗波特兰水泥，是一种理想的抗腐蚀胶凝材料，抗腐蚀系数> 1.0.</span>
            </div>
            <div class="dialog_Product_Introduction_content_top_div_right flex-column-align-center">
              <img style="width: 128px;height: 161px;"
                src="https://www.xjjcjc.com:7443/api/henghe/blob/b6ccbc5b7b3dc8dc38ca3a0e9c62174b.jpg" alt="">
              <el-button style="background-color: rgba(4, 52, 140, 1); color:#fff;width: 118px;margin-top:15px" round
                @click="gobuy('1000414500')">立刻购买</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-space-center">
        <div class="Product_Introduction_content_top_div flex-row margin-b-50">
          <img style="width:528px;height:100% "
            src="https://www.xjjcjc.com:7443/api/henghe/blob/452f4b263602794dfee83a0e9c61e010.jpg" alt="">
          <div class="Product_Introduction_content_top_div_title">低碱度硫铝酸盐水泥</div>
          <div class="dialog_Product_Introduction_content_top_div flex-row-start-between">
            <div class="dialog_Product_Introduction_content_top_div_left">
              <div class='dialog_title'>简介</div>
              <span class="dialog_title_span">低碱度硫铝酸盐水泥:在以无水硫铝酸钙为主要成分的硫铝酸盐水泥熟料中配入较多量的石有和石灰石，磨细制成的具有碱度低的水硬性胶凝材料，代号L.SAC。
                其强度等级以7d抗压强度表示，分为32.5、42.5、 52.5三个等级。</span>
              <div class='dialog_title'>优点</div>
              <span class="dialog_title_span"
                style="line-height: 24px">既有早强特性，又有碱度低、稳定期短、干缩率小的优点，与玻璃纤维复合强度保留率高，使用寿命长，是GRC制品的专用水泥。</span>
            </div>
            <div class="dialog_Product_Introduction_content_top_div_right flex-column-align-center">
              <img style="width: 128px;height: 161px;"
                src="https://www.xjjcjc.com:7443/api/henghe/blob/c250a76e2f53dcb70a793a0e9c61905b.jpg" alt="">
              <el-button style="background-color: rgba(4, 52, 140, 1); color:#fff;width: 118px;margin-top:15px" round
                @click="gobuy('1000416200')">立刻购买</el-button>
            </div>
          </div>
        </div>
      </div>
      <el-button class="pay-more" @click="buyall" round>购买更多特种水泥商品 >>></el-button>
    </div>
    <!-- 高端产品 -->
    <div class="hightline_serve">
      <div class="hightline_serve_div">
        <span class="hightline_serve_title">high-end product</span>
        <span class="hightline_serve_title1">高端产品</span>
        <div class="hightline_serve_title2"></div>
        <div class="hightline_serve_content flex-row">
          <div class="hightline_serve_content_img" v-for="(item,index) in shuinicontent" :key="index">
            <img class="hightline_img" :src="item.src" alt=""  @click="shuini_contentclick(item,index)">
            <div class="hightline_img_button"
              :style="item.colorchoose == true ? 'background-color: rgba(3, 38, 103, 1);color:#fff' : ''"
              @click="shuini_contentclick(item,index)">{{item.title}}</div>
          </div>
        </div>
        <div class="hightline_serve_uphold flex-row-space-between">
          <div class="hightline_serve_uphold_left">
            <span class="hightline_serve_uphold_left_title">{{shuini_child_content[chooseindex].title}}</span>
            <div class="hightline_serve_uphold_left_content">{{shuini_child_content[chooseindex].content}}</div>
          </div>
          <div class="hightline_serve_uphold_right">
            <img :src="shuini_child_content[chooseindex].img" alt="">
          </div>
        </div>
        <div class="hightline_serve_bottom_Number">
          <!-- 全国可发货，更多特种水泥请联系xxx 1398888888 -->
        </div>
      </div>
    </div>
    <!-- 企业优势 -->
    <div class="enterprise_advantages flex-space-center">
      <div class="enterprise_advantages_div ">
        <div class="enterprise_advantages_div_title">Enterprise advantages</div>
        <div class="enterprise_advantages_div_than">企业优势</div>
        <div class="enterprise_advantages_div_line"></div>
        <div class="flex-row margin-t-20">
          <div class="enterprise_advantages_content" v-for="(item,index) in enterpriseList" :key="index">
            <img :src="item.background" class="enterprise1_advantages_background" alt="">
            <img :src="item.img" alt="" class="enterprise2_advantages_background" />
            <p class="enterprise2_advantages_title">{{`0`+(index+1)}}</p>
            <div class="enterprise2_advantages_content">
              <div class="enterprise2_advantages_content_text">{{item.title}}</div>
              <div class="enterprise3_advantages_content">
                {{item.content}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 工程展示 -->
    <div class="Mem_show flex-space-center">
      <div class="Mem_show_div">
        <div class="Mem_show_div_title1">Enterprise advantages</div>
        <div class="Mem_show_div_title2">工程展示</div>
        <div class="Mem_show_div_line"></div>
        <div class="Mem_show_button_list">
          <el-button v-for="(item,index) in Main_memlist" :key="index"
            :style="item.colorshow == false ? 'background-color: rgba(255, 255, 255, 0);color: #04348C;border-color: rgba(4, 52, 140, 1);' : ''"
            round class="Mem_show_button_list_button" @click="Mem_click(index)">{{item.title}}</el-button>

        </div>
        <div class='Mem_show_img_background flex-row'>
          <div class="Mem_show_imgbackground" v-for="(item,index) in MemList[Memindex]" :key="index">
            <img style="width: 353px;height: 226px;" :src="item.img" alt="">
            <div>{{item.title}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- qualifications_show -->
    <div class="qualifications_show flex-space-center">
      <div class="qualifications_show_div">
        <div class="qualifications_show_div_title1">Corporate Honors</div>
        <div class="qualifications_show_div_title2">企业荣誉</div>
        <div class="qualifications_show_div_line"></div>
        <div class="qualifications_show_img_background  flex-row margin-tb-20">
          <div class="qualifications_show_imgbackground " v-for="(item,index) in imgList" :key="index">
            <img style="width: 352px;height: 244px;" :src="item.src" alt="">
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions
} from 'vuex';
export default {
  data () {
    return {
      chooseindex: 0,
      Memindex: 0,
      screenWidth: null,
      backgroundimgList: [
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/c59a3ce2c7af27d9d5da3a0e9c64ff94.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/37bb291f43839b4695673a0e9c659f90.png' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/a609a2a624d89a9ca7213a0e9c65f3be.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/9c9f1067894b493e00ee3a0e9c664169.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/f7470bf6494bdc9094dc3a0e9c66aaf2.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/74663ad4c2b57a2927723a0e9c6736e1.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/db39aab0b1e657812ce73a0e9c67bc7a.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/28cc47e60722ae551e583a0e9c686661.jpg' },
      ],
      imgList: [
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/d87582db4a10141db4d23a0e9c69c4e9.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/dcbce5760c0843502a863a0e9c6a1c99.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/a1cb8cb11404e9c7bf083a0e9c6a60cc.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/00ca492e8ce0e364ce543a0e9c6aa9dc.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/f32ee01355f74bb1796f3a0e9c6ae1b1.jpg' },
        { title: 'img1', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/5bfb95eb8d00c69bb7893a0e9c6b1fd9.jpg' },
      ],
      shuinicontent: [
        { title: '海洋深水固井水泥', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/ce800ec858c17753b6263a0e9c62cf7a.jpg', colorchoose: true, },
        { title: '稠油热采固井水泥', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/4078e4dd1a3148adde503a0e9c638930.jpg', colorchoose: false },
        { title: '改性高贝利特水泥', src: 'https://www.xjjcjc.com:7443/api/henghe/blob/603b105f6178a70983913a0e9c641e1a.jpg', colorchoose: false },
      ],
      shuini_child_content: [
        { title: '海洋深水固井水泥', content: '嘉华水泥G级油井水泥被“海洋石油981”深水钻井平台选定为固井专用油井水泥，打破国外油服公司的技术垄断，具有优异的低温早强性能和防窜性能，能满足后续开钻的强度要求，保障深海固井作业安全，保障深水油气资源高效安全开采', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/7c1534056c1075695ff63a0e9c632b2d.jpg' },
        { title: '稠油热采固井水泥', content: '嘉华水泥根据稠油热采工艺开发了磷铝酸盐水泥体系，采用磷铝酸盐水泥熟料与活性外掺料混磨而成，其常温条件下的水化产物主要组成为Ca2P2O7.2H2O、AI(OH)3，在高温高压条件下水化产物转化为Ca10（PO4）6(OH)2（羟基磷灰石）、AIO(OH)(勃姆石)、Ca2AI2Si2O8（长石），具有良好抗高温、抗热震性能，该水泥具有良好的抗CO2腐蚀性能。', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/9608158913ca5135f6843a0e9c63d7bf.jpg' },
        { title: '改性高贝利特水泥', content: '双块式无砟轨道由于道床板为现浇混凝土而双块式轨枕为预制混凝土结构，这会在两种混凝土结构的接触面上产生相对位移,这种差异会加大混凝土的裂纹产生几率,从而影响无砟轨道结构的混凝土耐久性。嘉华股份与中国铁道科学研究院合作,在宝兰高铁线上进行线下段双块式混凝土道床板的混凝土试验。', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/74fb577def5071bd1caf3a0ea165e86c.png' },
      ],
      enterpriseList: [
        { background: 'https://www.xjjcjc.com:7443/api/henghe/blob/6489a9f3194836cd2e5f3a0e9c4eca96.jpg', img: require('../../assets/enterprise5.png'), title: '高新技术企业', content: '公司为国家高新技术企业、国家技术创新示范企业、国家知识产权优势企业，通过“私人定制”“轻资产运营”等模式，始终保持在特种水泥行业的领先地位。' },
        { background: 'https://www.xjjcjc.com:7443/api/henghe/blob/4c3b94ac59dbff7751213a0e9c4f6158.jpg', img: require('../../assets/enterprise5.png'), title: '先进生产线', content: '公司在华北（石家庄）、西北（银川）、西南（乐山、西昌）等地建设有生产基地，拥有年产特种水泥450万吨的生产能力，是目前国内最大的特种水泥生产研发企业。' },
        { background: 'https://www.xjjcjc.com:7443/api/henghe/blob/59b52c43ec561d2c37e83a0e9c4f1fee.jpg', img: require('../../assets/enterprise5.png'), title: '先进技术和专业团队', content: '公司特种水泥研究院拥有混凝土、固井材料、微观结构分析、水泥基材料等5个实验室，为国家认定企业技术中心、国家认可实验室、博士后创新实践基地、建筑材料行业固井工程用油井水泥及材料重点实验室，承担了多个国家级科研项目并屡获殊荣，是水泥行业中承担国家科技项目最多的企业。目前已累计开发、可生产水工、核工、油井、海工、道路等8大类116个品种' },
        { background: 'https://www.xjjcjc.com:7443/api/henghe/blob/f94c128bc6c2cf600d7d3a0e9c4fa277.jpg', img: require('../../assets/enterprise5.png'), title: '严格把控产品质量', content: '公司不断地总结、优化工艺配方和控制参数。确保各类特种水泥产品的质量和稳定' }
      ],
      Main_memlist: [
        { title: '水电工程', colorshow: true },
        { title: '石油天然气', colorshow: false },
        { title: '路桥工程', colorshow: false },
      ],
      MemList: [
      [{ title: '白鹤滩水电站', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/12ccae7fc08077b71ecb3a0e9c4c9dd5.jpg' },
        { title: '官地水电站', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/87e110e38bb063af01613a0e9c4cf93f.jpg' },
        { title: '猴子岩水电站', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/8818d912b7f8f9c004173a0e9c4d3665.jpg' },
        { title: '锦屏水电站', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/7c83c1341c113600b5943a0e9c4d7f02.jpg' },
        { title: '两河口水电站', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/1dca411c9b0bb4cba6163a0e9c4dcaee.jpg' },
        { title: '乌东德水电站', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/68e273f2ab2c9b3825223a0e9c4e1cee.jpg' }],
        [{ title: '大庆油田', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/4384efca3536596770363a0e9c4a897a.jpg' },
        { title: '海洋石油981钻井平台', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/33537a147a95b5a6e6573a0e9c4ac9d3.jpg' },
        { title: '吉林油田', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/0691a5a943c0ffbfa2013a0e9c4b0f17.jpg' },
        { title: '冀东油田', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/aa71bbe9dc6f8d23112c3a0e9c4b4e9c.jpg' },
        { title: '辽河油田', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/0e3af764ea6e4ee434923a0e9c4bbcb9.jpg' },
        { title: '塔里木油田', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/f3b5aa9a54deec77b9093a0e9c4bffeb.jpg' }],
        [{ title: '宝兰线高铁', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/4c451c2d740111dcfe143a0e9c48537a.jpg' },
        { title: '成昆铁路复线峨米段', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/8fbae6b963adf95e357d3a0e9c48b10f.jpg' },
        { title: '川藏铁路', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/21cdd3a26425c8bcd1d83a0e9c490b15.jpg' },
        { title: '大坪山隧道1号横洞', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/7cc07805b64f304280ca3a0e9c495fd9.jpg' },
        { title: '拖乌山遂道', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/bd66bb74074061ef28e53a0e9c49c311.jpg' },
        { title: '西昌旅游客运中心', img: 'https://www.xjjcjc.com:7443/api/henghe/blob/c8dfcdb1a3c51455f2743a0e9c4a199d.jpg' }],
      ]
    }
  },
  mounted () {
    this.screenWidth = Number(window.screen.width)
    console.log(this.screenWidth > 2100)
  },
  computed: {

  },
  // 组件失活时
  deactivated () {
    this.changeSearchData('')

  },
  methods: {
    ...mapActions(['changeSearchData', 'changeshouPathValue', 'changeclassifyListData', 'changehomeIsGoodsData']),
    buyall () {
      this.$router.push({
        path: "/Goods",
        query: {
          edit: false,
        },
      });
      this.changeSearchData('特种水泥')
    },
    gobuy (id) {
      this.$router.push({
					path: '/Goods/GoodsDetails',
					query: {
						fGoodsID: id
					}
				})
      // this.$router.push({
      //   path: "/Goods",
      //   query: {
      //     edit: false,
      //   },
      // });
      // this.changeSearchData('特种水泥')
    },
    Mem_click (index) {
      this.Memindex = index
      this.Main_memlist.forEach((tag, num) => {
        tag.colorshow = false
        if (num == index) {
          tag.colorshow = true
        }
      })
    },
    shuini_contentclick (item, index) {
      this.chooseindex = index
      console.log(item, index)
      this.shuinicontent.forEach((tag, num) => {
        tag.colorchoose = false
        if (num == index) {
          tag.colorchoose = true
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.cement_main {
  .pay-more {
    background-color: rgba(227, 233, 244, 1);
    color: #04348c;
    width: 262px;
    margin-top: 15px;
    position: absolute;
    left: calc(58% - 262px);
  }
  width: 100%;
  height: 5658px;
  // border: 1px solid red;
  position: relative;
  top: 0px;
  left: 0px;
  .background_img {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 395px;
    .background_img_title {
      width: 382px;
      position: absolute;
      top: 20%;
      left: calc(60% - 382px);
      box-shadow: none;
      font-family: "钉钉进步体 Bold", "钉钉进步体 Regular", 钉钉进步体;
      font-weight: 700;
      font-style: normal;
      font-size: 40px;
      color: rgb(255, 255, 255);
      background: inherit inherit inherit inherit inherit inherit inherit
        inherit inherit;
      border-width: initial;
      border-style: none;
      border-color: initial;
      border-image: initial;
      border-radius: 0px;
      text-shadow: 1px 1px 5px rgba(0, 0, 153, 1);
    }
    .background_img_title_two {
      width: 166px;
      height: 24px;
      font-family: "Arial Normal", "Arial";
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
      color: #ffffff;
      white-space: nowrap;
      text-shadow: 1px 0px 5px rgba(0, 0, 153, 1);
      position: absolute;
      top: 2px;
      top: 36%;
      left: calc(65% - 382px);
    }
    .background_img_title_line {
      position: absolute;
      top: 45%;
      left: calc(60% - 382px);
      width: 370px;
      height: 2px;
      background: #ffffff;
    }
    .background_img_title_three {
      position: absolute;
      top: 50%;
      left: calc(64% - 382px);
      align-items: center;
      font-family: "阿里巴巴普惠体 Medium", "阿里巴巴普惠体 Regular",
        "阿里巴巴普惠体";
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      color: #1e1e1e;
    }
  }
  .introduce {
    position: absolute;
    top: 339px;
    left: 0px;
    width: 100%;
    justify-content: center;
    .introduce_background {
      width: calc(1100px - 103px);
      height: 375px;
      background: inherit;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 10px rgba(51, 51, 153, 0.349019607843137);
      padding: 53px 53px;
      .introduce_background_top {
        width: 100%;
        height: 160px;
        .introduce_background_top_left {
          width: calc(100% - 776px);
          height: 100%;
          .introduce_background_top_left_line {
            margin-top: 9px;
            margin-bottom: 9px;
            width: 99px;
            height: 3px;
            background: #003399;
          }
        }
        .introduce_background_top_right {
          width: 766px;
          height: 150px;
          font-family: 400;
          font-weight: normal;
          line-height: 30px;
          text-indent: 2em;
        }
      }
      .introduce_background_bottom {
        width: 100%;
        height: 219px;
        .introduce_background_bottom_left {
          text-align: center;
          height: 100%;
          width: 128px;
          display: table-cell;
          vertical-align: middle;
          background: #04348c;
          font-size: 24px;
          font-family: 700;
          font-weight: bold;
          color: #ffffff;
        }
        .introduce_background_bottom_right {
          width: 869px;
          height: calc(100% - 2px);
          border: 1px solid;
          overflow: hidden;
          animation-delay: 3s;
          .introduce_animation {
            animation: scrollTop1 11s infinite linear;
            display: flex;
            .carousel-item {
              width: 217px;
              margin: 0px 1px;
              height: 100%;
              &:first-child {
                margin-left: 15px;
              }
            }
            @keyframes scrollTop1 {
              0% {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
              }
              100% {
                -webkit-transform: translate3d(-50vw, 0, 0);
                transform: translate3d(-50vw, 0, 0);
              }
            }
          }
        }
      }
    }
  }
  .serve_say {
    position: absolute;
    top: 860px;
    left: calc(55% - 194px);
    width: 194px;
    height: 72px;
    font-family: -400;
    font-weight: normal;
    text-align: center;
    line-height: 36px;
  }
  .serve_say_line {
    position: absolute;
    top: 947px;
    left: calc(55% - 147px);
    width: 99px;
    height: 3px;
    background: inherit;
    background-color: rgba(0, 51, 153, 1);
    border: none;
    border-radius: 0px;
  }
  .Product_Introduction_content {
    position: absolute;
    top: 1019px;
    left: 0px;
    width: 100%;
    .Product_Introduction_content_top_div {
      margin: 0 auto;
      // margin-left: 21%;
      position: relative;
      top: 0px;
      left: 0px;
      width: calc(1100px);
      height: 349px;
      .Product_Introduction_content_top_div_title {
        width: calc(1100px - 528px);
        text-align: right;
        font-family: "阿里巴巴普惠体 Light", "阿里巴巴普惠体 Regular",
          "阿里巴巴普惠体";
        font-weight: 300;
        font-style: normal;
        font-size: 48px;
        color: #003399;
      }
      .Product_Introduction_content_top_div_title-other {
        width: calc(1100px - 528px);
        text-align: left;
        font-family: "阿里巴巴普惠体 Light", "阿里巴巴普惠体 Regular",
          "阿里巴巴普惠体";
        font-weight: 300;
        font-style: normal;
        font-size: 48px;
        color: #003399;
      }
      .dialog_Product_Introduction_content_top_div {
        border-width: 0px;
        position: absolute;
        right: 0px;
        top: 50px;
        width: 630px;
        height: 220px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        border: none;
        border-radius: 0px;
        -moz-box-shadow: 0px 0px 10px rgba(51, 51, 153, 0.349019607843137);
        -webkit-box-shadow: 0px 0px 10px rgba(51, 51, 153, 0.349019607843137);
        box-shadow: 0px 0px 10px rgba(51, 51, 153, 0.349019607843137);
        padding: 25px;
        .dialog_Product_Introduction_content_top_div_left {
          width: 493px;
          height: 100%;
          .dialog_title_span {
            width: 493px;
            height: 48px;
            font-family: -400;
            font-weight: normal;
            color: #000000;
            margin-bottom: 11px;
          }
          .dialog_title {
            width: 32px;
            height: 36px;
            font-size: 15px;
            font-family: -700;
            font-weight: bold;
            text-align: center;
            color: #003399;
            line-height: 36px;
            border-bottom: 3px solid #003399;
            margin-bottom: 15px;
          }
        }
      }
      .dialog_Product_Introduction_content_top_div-other {
        border-width: 0px;
        position: absolute;
        left: 0px;
        top: 50px;
        width: 630px;
        height: 220px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        border: none;
        border-radius: 0px;
        -moz-box-shadow: 0px 0px 10px rgba(51, 51, 153, 0.349019607843137);
        -webkit-box-shadow: 0px 0px 10px rgba(51, 51, 153, 0.349019607843137);
        box-shadow: 0px 0px 10px rgba(51, 51, 153, 0.349019607843137);
        padding: 25px;
        .dialog_Product_Introduction_content_top_div_left {
          width: 493px;
          height: 100%;
          .dialog_title_span {
            width: 493px;
            height: 48px;
            font-family: -400;
            font-weight: normal;
            color: #000000;
            margin-bottom: 11px;
          }
          .dialog_title {
            width: 32px;
            height: 36px;
            font-size: 15px;
            font-family: -700;
            font-weight: bold;
            text-align: center;
            color: #003399;
            line-height: 36px;
            border-bottom: 3px solid #003399;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .hightline_serve {
    position: absolute;
    top: 2400px;
    width: 100%;
    height: 950px;
    background: inherit;
    background-color: rgba(4, 52, 140, 1);
    box-shadow: none;
    // text-align: center;
    .hightline_serve_div {
      height: 950px;
      position: relative;
      top: 0px;
      left: 0px;
      display: flex;
      justify-content: center; /* 水平居中 */
      .hightline_serve_title {
        // margin-top: 28px;
        position: absolute;
        top: 28px;
        left: calc(60% - 252px);
        font-size: 22px;
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
        color: #fff;
      }
      .hightline_serve_title1 {
        // margin-top: 28px;
        position: absolute;
        top: 58px;
        left: calc(60% - 228px);
        font-size: 30px;
        font-family: "Arial Normal", "Arial";
        font-weight: 700;
        color: #fff;
      }
      .hightline_serve_title2 {
        display: inline-block;
        position: absolute;
        top: 115px;
        left: calc(60% - 218px);
        width: 99px;
        height: 3px;
        background: #fff;
        // border: 1px solid;
      }
      .hightline_serve_content {
        width: 1114px;
        position: absolute;
        top: 135px;
        .hightline_serve_content_img {
          width: 355px;
          height: 322px;
          margin-right: 25px;
          background-color: rgba(255, 255, 255, 1);
          .hightline_img {
            width: 325px;
            height: 238px;
            margin: 15px 14px;
          }
          .hightline_img_button {
            width: 355px;
            height: 54px;
            background: rgba(242, 242, 242, 1);
            font-family: "Arial Negreta", "Arial";
            font-weight: 700;
            font-style: normal;
            font-size: 16px;
            color: #000000;
            text-align: center;
            line-height: 54px;
          }
        }
      }
      .hightline_serve_uphold {
        margin-top: 60px;
        width: 1114px;
        height: 304px;
        position: absolute;
        top: 465px;
        .hightline_serve_uphold_left {
          width: 630px;
          height: 304px;
          .hightline_serve_uphold_left_title {
            display: block;
            width: 304px;
            height: 152px;
            line-height: 152px;
            text-align: center;
            font-family: "Arial Negreta", "Arial";
            font-weight: 700;
            font-style: normal;
            font-size: 36px;
            color: #ffffff;
          }
          .hightline_serve_uphold_left_content {
            width: 630px;
            height: 152px;
            text-indent: 2em;
            color: #ffffff;
            line-height: 24px;
            font-family: "Arial Normal", "Arial";
            font-weight: 400;
            font-style: normal;
            font-size: 13px;
          }
        }
        .hightline_serve_uphold_right {
          width: 382px;
          height: 304px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .hightline_serve_bottom_Number {
        width: 1114px;
        height: 54px;
        position: absolute;
        top: 865px;
        font-size: 15px;
        color: rgba(255, 255, 255, 0.509803921568627);
        text-align: center;
        line-height: 54px;
      }
    }
  }
  .enterprise_advantages {
    position: absolute;
    top: 3420px;
    width: 100%;
    height: auto;
    background: inherit;
    // border: 1px solid;
    // background-color: rgba(4, 52, 140, 1);
    box-shadow: none;
    .enterprise_advantages_div {
      width: 1120px;
      // height: 100px;
      // border: 1px solid;
      .enterprise_advantages_div_title {
        font-family: "Arial Normal", "Arial";
        font-weight: 400;
        color: #003399;
        font-size: 22px;
        text-align: center;
      }
      .enterprise_advantages_div_than {
        font-family: "Arial Negreta", "Arial";
        font-weight: 700;
        color: #000000;
        font-size: 30px;
        text-align: center;
        margin-bottom: 14px;
      }
      .enterprise_advantages_div_line {
        width: 92px;
        height: 3px;
        background: inherit;
        background-color: rgba(0, 51, 153, 1);
        border: none;
        border-radius: 0px;
        margin: 0 auto;
      }
      .enterprise_advantages_content {
        position: relative;
        top: 0px;
        left: 0px;
        width: 268px;
        height: 459px;
        margin-right: 10px;
        border: 1px solid rgba(227, 233, 244, 1);
        .enterprise1_advantages_background {
          width: 268px;
          height: 145px;
          display: block;
        }
        .enterprise2_advantages_background {
          width: 58px;
          height: 58px;
          display: block;
          position: absolute;
          top: 115px;
          left: 105px;
        }
        .enterprise2_advantages_title {
          font-weight: 700;
          font-style: normal;
          font-size: 24px;
          color: #ffffff;
          position: absolute;
          top: 127px;
          left: 120px;
        }
        .enterprise2_advantages_content {
          height: 314px;
          width: 268px;
          .enterprise2_advantages_content_text {
            padding: 60px 0px 30px 0px;
            text-align: center;
            font-family: "Arial Negreta", Arial;
            font-weight: 700;
            font-size: 18px;
          }
          .enterprise3_advantages_content {
            padding: 0px 12px;
            font-family: "Arial Normal", Arial;
            font-weight: 400;
            color: #000000;
          }
        }
      }
    }
  }
  .Mem_show {
    position: absolute;
    top: 4050px;
    width: 100%;
    height: auto;
    background: inherit;
    background-color: rgba(245, 247, 251, 1);
    .Mem_show_div {
      width: 1100px;
      // height: 100px;
      // border: 1px solid;
      .Mem_show_div_title1 {
        width: 100%;
        margin-top: 30px;
        text-align: center;
        font-family: "Arial Normal", "Arial";
        font-size: 22px;
        font-weight: 400;
        color: #003399;
      }
      .Mem_show_div_title2 {
        width: 100%;
        font-size: 30px;
        text-align: center;
        font-family: "Arial Negreta", "Arial";
        font-weight: 700;
        color: #000000;
        margin-bottom: 15px;
      }
      .Mem_show_div_line {
        width: 92px;
        height: 3px;
        background: inherit;
        background-color: rgba(0, 51, 153, 1);
        border: none;
        border-radius: 0px;
        margin: 0 auto;
      }
      .Mem_show_button_list {
        width: 540px;
        height: 41px;
        // border: 1px solid;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        .Mem_show_button_list_button {
          width: 173px;
          height: 41px;
          background: inherit;
          background-color: rgba(3, 38, 103, 1);
          font-size: 14px;
          color: #fff;
          font-weight: 500;
        }
      }
      .Mem_show_img_background {
        width: 1100px;
        flex-wrap: wrap;
        .Mem_show_imgbackground {
          width: 353px;
          height: 307px;
          margin-top: 5px;
          margin-left: 10px;
          text-align: center;
          // border: 1px solid;
        }
      }
    }
  }
  .qualifications_show {
    position: absolute;
    top: 4890px;
    width: 100%;
    height: auto;
    background: inherit;
    background-color: rgba(0, 51, 153, 1);
    .qualifications_show_div {
      width: 1100px;
      // height: 100px;
      // border: 1px solid;
      .qualifications_show_div_title1 {
        width: 100%;
        margin-top: 30px;
        text-align: center;
        font-family: "Arial Normal", "Arial";
        font-size: 22px;
        font-weight: 400;
        color: #fff;
      }
      .qualifications_show_div_title2 {
        width: 100%;
        font-size: 30px;
        text-align: center;
        font-family: "Arial Negreta", "Arial";
        font-weight: 700;
        color: #fff;
        margin-bottom: 15px;
      }
      .qualifications_show_div_line {
        width: 92px;
        height: 3px;
        background: inherit;
        background-color: #fff;
        border: none;
        border-radius: 0px;
        margin: 0 auto;
      }
      // .Mem_show_button_list {
      //   width: 540px;
      //   height: 41px;
      //   // border: 1px solid;
      //   margin: 0 auto;
      //   margin-top: 30px;
      //   margin-bottom: 30px;
      // }
      .qualifications_show_img_background {
        width: 1100px;
        flex-wrap: wrap;
        margin-top: 50px;
        margin-bottom: 80px;
        .qualifications_show_imgbackground {
          width: 353px;
          height: 257px;
          margin-top: 5px;
          margin-left: 10px;
          // border: 1px solid;
        }
      }
    }
  }
}
</style>